import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import React, { ReactElement, ReactPortal } from "react";
import { getStorageData, setStorageData } from "framework/src/Utilities";
interface ReactText {
  value: string | number;
}

interface ReactChild {
  value: ReactElement | ReactText;
}

interface ReactNodeArray extends Array<ReactNode> {}

interface ReactFragment {
  children: ReactNodeArray | {};
}

interface ReactNode {
  value: ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  drawerContent?: boolean;
  classes?: Record<string, string>;
  children?: string | JSX.Element | JSX.Element[] | React.ReactElement
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  isMobile: boolean;
  selectedLanguage:string;
  ActivePathnameState: string
  // Customizable Area End
}

interface SS {
    // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationRaiseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTermAndConditionMessage)
      
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      isMobile: this.checkIfMobile(),
      selectedLanguage:"",
      ActivePathnameState : ""
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
    // Customizable Area Start
    window.addEventListener("resize", this.handleResize);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  languageChange = (language: string) => {
    setStorageData("language", language);
    location.reload();
  };
  
  checkIfMobile = (): boolean => {
    return typeof window !== "undefined" && window.innerWidth < 900;
  };

  handleResize = () => {
    this.setState({ isMobile: this.checkIfMobile() });
  };

  toggleDrawer = () => {
    this.setState((prevState) => ({ webDrawer: !prevState.webDrawer }));
  };

   ActivePathname = location.pathname

  onPressMenuItem = (screenName:string) => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
 
  };
  async componentWillUnmount(): Promise<void> {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.handleResize);
    }
  }

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.setState({ ActivePathnameState: this.ActivePathname })
    const language = await getStorageData("language") || "English";
    this.setState({ selectedLanguage: language })
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMenuItems = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
