import React from "react";


// Customizable Area Start

import { Box, Typography, Button, Snackbar, Alert } from '@mui/material'

// Customizable Area End

import Cfopenbankingapi1Controller, {
  Props,
  configJSON,
} from "./Cfopenbankingapi1Controller";

export default class Cfopenbankingapi1 extends Cfopenbankingapi1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
        <Box sx={styles.mainWrapper}>
          <Typography sx={styles.titleText}>{configJSON.labelTitleText}</Typography>
          <Box sx={styles.inputStyle}>
            Proceed to lean connect
          </Box>
          {this.state.sendDataMsg && (<Snackbar data-test-id="snackBarId" anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={4000} onClose={() => this.handleSnackbarself()}
            sx={styles.snackbarStyle} open={this.state.isSnackbarError || this.state.isSnackbarSuccess}  >
            <Alert severity={this.state.isSnackbarError ? "error" : "success"}> {this.state.sendDataMsg} </Alert>
          </Snackbar>)}
          <Box style={styles.buttonStyle}>
            <Button
              sx={styles.button}
              color='inherit'
              data-test-id="btnAddExample"
              onClick={() => this.handleSubmit()}
            >Next</Button>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems:'center',
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "30%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
    color: '#FFF',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '100%'
  },
  snackbarStyle:{
    position: 'absolute',
    top:'30%',
    bottom:'70%'
  },
};


// Customizable Area End
