Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Openbankingapi";
exports.labelBodyText = "cfopenbankingapi1 Body";
exports.leanConnection= "https://cdn.leantech.me/link/loader/prod/sa/latest/lean-link-loader.min.js";
exports.btnExampleTitle = "CLICK ME";
exports.createCustomerApiEndPoint = "/bx_block_cfopenbankingapi1/banking/create_customer";
exports.leanClientId="91127153-13e0-46bf-9342-617c0683ebc8";
exports.snackbarAlertMsg= "Error creating customer: A customer already exists for the app_user_id you have provided.";
// Customizable Area End