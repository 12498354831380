import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// Customizable Area Start
import {
  Container,
  Typography,
  Box,
  Input,
  Button,
  InputAdornment,
  Divider
} from "@mui/material";
import { eyeSvg, crossEyeSvg, headerLogo, checkIcon } from "./assets";
import { Formik, Field, Form, ErrorMessage } from 'formik';
// Customizable Area End

import ForgotPasswordResetController, {
  Props
} from "./ForgotPasswordResetController";
// Customizable Area Start

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
      secondary: {
        main: "#376fd0",
        contrastText: "#fff",
      },
      error: {
        main: "#f44336",
        contrastText: "#fff",
      }
    },
    typography: {
      fontFamily: 'Poppins, sans-serif',
      h1: {
        fontWeight: 700,
        fontSize: "28px",
      },
      h6: {
        fontWeight: 500,
      },

      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
// Customizable Area End


export default class ForgotPasswordReset extends ForgotPasswordResetController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    // Customizable Area Start
    
    const { passwordVisible, passwordVisible2 } = this.state

    // Customizable Area End

    return (
      // Customizable Area Start
      <div id="main_block">
        <ThemeProvider theme={theme}>
      <Box sx={Styles.ForgotHeader}>
<Box
data-test-id = 'header-logo1'
component="img"
src={headerLogo.default}
alt="headerImage"
onClick={() => this.onPressMenuItem("Home")}
sx={Styles.HeaderLogoStyle}
/>
</Box>
<Divider sx={Styles.DividerStyling} />
          {this.state.passwordResetSuccessful ? (
            <>
              <Box sx={Styles.SuccessWrapper}>
                <Box sx={Styles.checkImage}>
                  <img src={checkIcon.default} alt="Check Image" />
                </Box>
                <Typography sx={Styles.SuccessText}>
                  Your password has been <br /> successfully reset!
                </Typography>
                
                <Button
                  data-test-id="login-button"
                  sx={Styles.LoginButton}
                  onClick={() => this.onPressMenuItem("Login")}
                >
                  Login
                </Button>
              </Box>
            </>
          ) : (
            <Box>
              <Container maxWidth="xl" sx={Styles.mainDivStyle}>
                <Typography variant="h4" gutterBottom sx={Styles.HeadingStyle}>
                  Reset your password
                </Typography>
                <Formik
                  data-test-id="formik"
                  initialValues={{ password: "", confirmPassword: "" }}
                  validationSchema={this.state.passwordSchema}
                  onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                >
                  {({ errors, touched, isSubmitting }) => (
                    <Form>
                      <Box sx={Styles.InputMainDivStyle}>
                        <Field
                          name="password"
                          data-test-id="password-input"
                          type={passwordVisible ? "text" : "password"}
                          as={Input}
                          sx={
                          Styles.PhoneInput1 (errors.password, touched.password)}
                          placeholder="Enter Password"
                          endAdornment={
                            <InputAdornment position="end">
                              <img
                                data-test-id="password-img"
                                src={passwordVisible ? eyeSvg.default : crossEyeSvg.default}
                                alt="toggle visibility"
                                style={Styles.phoneIconStyle}
                                onClick={() =>
                                  this.setState({ passwordVisible: !passwordVisible })
                                }
                              />
                            </InputAdornment>
                          }
                        />
                        <Box sx={Styles.PhoneInputError}>
                          <ErrorMessage name="password" component="" />
                        </Box>

                        <Field
                          name="confirmPassword"
                          data-test-id="confirm-password-input"
                          type={passwordVisible2 ? "text" : "password"}
                          as={Input}
                          sx={
                            Styles.PhoneInput2 (errors.confirmPassword, touched.confirmPassword)}
                          placeholder="Confirm Password"
                          endAdornment={
                            <InputAdornment position="end">
                              <img
                                data-test-id="confirm-password-img"
                                src={passwordVisible2 ? eyeSvg.default : crossEyeSvg.default}
                                alt="toggle visibility"
                                style={Styles.phoneIconStyle}
                                onClick={() =>
                                  this.setState({ passwordVisible2: !passwordVisible2 })
                                }
                              />
                            </InputAdornment>
                          }
                        />
                        <Box sx={Styles.PhoneInputError}>
                          <ErrorMessage
                            data-test-id="confirm-password-error-message"
                            name="confirmPassword"
                            component=""
                          />
                        </Box>

                        <Button
                          data-test-id="password-reset-btn"
                          type="submit"
                          sx={Styles.NextButton}
                          disabled={isSubmitting}
                        >
                          Reset
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Container>
            </Box>
          )}
        </ThemeProvider>
      </div>

      // Customizable Area End
    );
  }
}
// Customizable Area Start

const Styles = {
  
  DividerStyle: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    },
  },
  InputMainDivStyle: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  mainDivStyle: {
    marginTop: "150px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    '@media (max-width:900px)': {
      marginTop: "45px",
    },
    '@media (max-width:599px)': {
      marginTop: "38px",
    },
  },
  DividerStyling: {
    marginTop: "20px",
    '@media (min-width:901px)': {
      display: "none"
    },
  },
  HeaderLogoStyle: {
    height: "auto",
    width: "auto",
      '@media (max-width:900px)': {
        height: "34px",
        width: "92px",
      },
      [theme.breakpoints.down("sm")]: {
        height: "34px",
        width: "92px",
      },
    },
    ForgotHeader: {
      '@media (min-width:1300px)': {
        marginLeft: "226px",
    marginTop: "30px",
      },
      '@media (max-width:1300px)': {
        marginLeft: "99px",
        marginTop: "30px",
      },
    '@media (max-width:900px)': {
      display:"flex",
      margin: "25px 0px 0px 0px",
      justifyContent: "center",
      alignItems: "center",
        },
        '@media (max-width:599px)': {
          display:"block",
          margin: "20px 0px 25px 16px",
        },
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0px 25px 16px",
    },
    },
  PhoneInputError: {
    width:"100%",
    textAlign: "left",
    marginBottom: "24px",
    alignItems: "left !important",
    color: "#DC2626",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    '@media (max-width:900px)': {
      marginBottom: "23px",
      },
    '@media (max-width:599px)': {
      marginBottom: "24px",
      },
  },
  HeadingStyle: {
    fontWeight: 700,
    fontSize: "28px",
    marginBottom: "67px",
    fontFamily: "Poppins",
    lineHeight: "29.33px",
    textAlign: "center",
    '@media (max-width:900px)': {
      marginBottom: "67px",
    },
    '@media (max-width:599px)': {
      marginBottom: "87px",
    },
  },
  PhoneInput2: (error: any, touched: any) => ({
    borderBottom: `2px solid ${error && touched ? "#DC2626" : "#CCF1FF"}`,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      borderBottom: '1px solid #6200EA !important',
    },
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
    width: "520px",
    height: "44px",
    marginBottom: "2px",
    backgroundColor: "transparent",
    '@media (max-width:900px)': {
      width: "551px",
      height: "44px",
    },
    '@media (max-width:599px)': {
      width: "343px",
      height: "44px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "343px",
      height: "44px",
    },
  }),
  phoneIconStyle: {
    marginRight: "8px",
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
  },
  PhoneInput1: (error: any, touched: any) => ({
    position: 'relative',
    borderBottom: `2px solid ${error && touched ? "#DC2626" : "#CCF1FF"}`,
    '&::before': {
      position: 'absolute',
      content: '""',
      right: 0,
      borderBottom: '1px solid #6200EA !important',
      left: 0,
    },
    fontSize: "16px",
    textAlign: "left",
    marginBottom: "2px",
    fontFamily: "Inter",
    lineHeight: "24px",
    fontWeight: "400",
    backgroundColor: "transparent",
    width: "520px",
    height: "44px",
    '@media (max-width:900px)': {
      height: "44px",
      width: "551px",
    },
    '@media (max-width:599px)': {
      height: "44px",
      width: "343px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "44px",
      width: "343px",
    },
  }),
  NextButton: {
    fontFamily: "Poppins",
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    width: "520px",
    height: "53px",
    padding: "19.5px 236px",
    borderRadius: "6px",
    backgroundColor: "#1D36BB",
    color: "#fff",
    fontWeight: 700,
    textTransform: "none",
    marginTop: "24px",
    '@media (max-width:900px)': {
      marginTop: "24px",
      width: "551px",
      },
    '@media (max-width:599px)': {
      width: "343px",
      height: "53px",
      marginTop: "0px",
      padding: "18px 51px",
      },
    "&:hover": {
      backgroundColor: "#1D36BB",
      color: "#fff",
      boxShadow: "none",
    },
  },
  SuccessText: {
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: "700",
    lineHeight: "29.33px",
    textAlign: "center",
    marginBottom: "42px",
    '@media (max-width:900px)': {
      marginBottom: "98px",
      },
    '@media (max-width:599px)': {
      marginBottom: "90px",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "30px",
      textAlign: "center"


    },
  },

  SuccessWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center"
  },

  checkImage: {
    textAlign: "center",
    marginTop: "87.99px",
    marginBottom: "42px",
    '@media (max-width:900px)': {
      marginTop: "52px",
      marginBottom: "31px",
      },
  },
  LoginButton: {
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "30px",
    textAlign: "center",
    width: "520px",
    height: "53px",
    padding: "18px 51px 18px 51px",
    gap: "10px",
    borderRadius: "6px",
    background: "#1D36BB",
    '@media (max-width:900px)': {
      width: "551px",
      height: "53px",
      },

    [theme.breakpoints.down("sm")]: {
      width: "343px",
      height: "53px",
      padding: "18px 51px",
    },
    "&:hover": {
      backgroundColor: "#1D36BB",
      color: "#fff",
      boxShadow: "none",
    },
  }
}
// Customizable Area End
