import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  Lean: any;
  componentPage: string;
  customer_id: string;
  sendDataMsg: string,
  isSnackbarError: boolean;
  isSnackbarSuccess: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfopenbankingapi1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createCustomerApiCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      Lean: '',
      componentPage: "home",
      customer_id: '',
      sendDataMsg: '',
      isSnackbarError: false,
      isSnackbarSuccess: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (this.createCustomerApiCallID === apiRequestCallId) {
      if (responseJson.error) {
        this.setState({ sendDataMsg: responseJson.error, isSnackbarError: true })
      }
      else {
        setStorageData("Customer_Access_Token", JSON.stringify(responseJson.Customer_Access_Token.access_token))
        this.setState({ customer_id: responseJson.customer.customer_id, });
        this.leanConnection(responseJson.customer.customer_id)
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleSnackbarself = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === configJSON.snackbarAlertMsg) { return; }
    this.setState({ isSnackbarError: false })
  };

  handleSubmit = async () => {
    const loginToken = await getStorageData("login_token", true)

    const header = {
      token: loginToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.createCustomerApiCallID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createCustomerApiEndPoint}?${loginToken}`)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      ""
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  leanConnection = async (customerId: string) => {
    const accessToken = await getStorageData("Customer_Access_Token", true);

    (window as any)?.Lean.connect({
      app_token: configJSON.leanClientId,
      permissions: ["identity", "accounts", "balance", "transactions", "identities", "scheduled_payments", "standing_orders", "direct_debits", "beneficiaries"],
      customer_id: customerId,
      sandbox: true,
      access_token: accessToken,
      fail_redirect_url: 'https://opensooqfs-632983-react.b632983.dev.eastus.az.svc.builder.cafe/LandingPage',
      success_redirect_url: 'https://opensooqfs-632983-react.b632983.dev.eastus.az.svc.builder.cafe/Login',
      account_type: 'personal',
    })
  };
  // Customizable Area End
}
