export const email = require("../assets/image_.svg");
export const logo = require("../assets/logo.png");
export const coin_image = require("../assets/image_(9).svg");
export const man_Img = require("../assets/image_.png");
export const women_Img = require("../assets/image_women.png");
export const image_first = require("../assets/image_(1).svg");
export const image_second = require("../assets/image_(2).svg");
export const image_third = require("../assets/image_(3).svg");
export const image_forth = require("../assets/image_(4).svg");
export const image_fifth = require("../assets/image_(5).svg");
export const image_sixth = require("../assets/image_(6).svg");
export const arrowDown = require("../assets/image_(7).svg");
export const arrowUp = require("../assets/image_(8).svg");
export const key_image = require("../assets/image_(10).svg");
export const arrowOpen = require("../assets/image_(11).svg");
export const arrowClose = require("../assets/image_(14).svg");
export const calender = require("../assets/calender.svg");






